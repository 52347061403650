import { FILE_STATUS } from 'src/constants/FileStatus';

import { StepperContextValue } from '../types';

import { currentStateIsFurtherOrEqualToFilter } from './utils';

const ACTIVE_STATE = FILE_STATUS.QUALITY_PROCESSING_COMPLETE;

const checkIfIsActive = (stepperContext: StepperContextValue) => {
    const { ovt, currentState, commitStatusQuery, ovtQuery } = stepperContext;
    const query = ovt ? ovtQuery : commitStatusQuery;
    const { isLoading, isFetching } = query;

    return (
        (isLoading || (isFetching && !isLoading)) &&
        currentStateIsFurtherOrEqualToFilter(ACTIVE_STATE, currentState)
    );
};

const checkIfIsError = (stepperContext: StepperContextValue) => {
    const { ovt, commitStatusQuery, ovtQuery } = stepperContext;
    const query = ovt ? ovtQuery : commitStatusQuery;
    const { isFetched, data } = query;
    if (!isFetched) return false;

    if (ovt) {
        return data[0]?.RuleFailuresOutsideTolerance > 0;
    } else {
        if (!data || !data?.submissionStatuses) return false;

        const {
            submissionStatuses: { openIssues, ruleFailures },
        } = data;
        return openIssues || ruleFailures;
    }
};

const checkIfIsComplete = (stepperContext: StepperContextValue) => {
    const { ovt, commitStatusQuery, ovtQuery } = stepperContext;

    const query = ovt ? ovtQuery : commitStatusQuery;
    const { isFetched, data } = query;

    if (!isFetched) return false;

    if (ovt) {
        return data[0]?.RuleFailuresOutsideTolerance === 0;
    } else {
        if (!data || !data?.submissionStatuses) return false;

        const {
            submissionStatuses: { openIssues, ruleFailures },
        } = data;
        return !!(!openIssues && !ruleFailures);
    }
};

export default {
    checkIfIsActive,
    checkIfIsError,
    checkIfIsComplete,
};
