import { List, ListItem } from '@mui/material';
import useSchemaChecks from 'src/queries/submissions/useSchemaChecks';
import { formatSchemaErrorIds } from 'src/utils/formatSchemaErrorIds/formatSchemaErrorIds';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './schemaFailuresReport.module.scss';

interface SchemaFailuresReportProps {
    instId: string;
    submissionId: number;
    isOvt?: boolean;
}

const SchemaFailuresReport = ({
    instId,
    submissionId,
    isOvt = false,
}: SchemaFailuresReportProps) => {
    const { data: schemaFailures } = useSchemaChecks({
        ovt: isOvt,
        instId,
        submissionId,
        offset: 0,
        limit: 101,
    });

    const schemaErrorSubsetMessage =
        schemaFailures?.length === 101
            ? 'This report is limited to 100 rows. To see all errors, please download the full report using the download icon next to Schema check failed'
            : '';

    return (
        <div className={styles.schemaFailuresReport}>
            <span className={styles.heading}>
                Schema validations errors
                <br />
                {schemaErrorSubsetMessage}
            </span>

            <List>
                {schemaFailures?.slice(0, 100).map((error, index) => {
                    return (
                        <ListItem
                            key={index}
                            data-test-id={formatTestId(
                                'schema validation error',
                                index.toString(),
                            )}
                            className={styles.listItem}
                            aria-label={`Schema validation error on line: ${error.LineNumber}: ${error.Error}`}
                        >
                            Line: {error.LineNumber}
                            <br />
                            Error: {error.Error}
                            <br />
                            Details: {formatSchemaErrorIds(error.Details)}
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};

export default SchemaFailuresReport;
