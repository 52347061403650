import React from 'react';
import { HourglassEmptyTwoTone } from '@mui/icons-material';
import Cached from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const STATUS_ICONS = {
    STATE_CAN_PROGRESS: <DoneIcon style={{ color: '#338164' }} />,
    STATE_USER_ACTION_NEEDED: <PriorityHighIcon style={{ color: '#B24600' }} />,
    STATE_FAILED: <CloseIcon style={{ color: '#A93439' }} />,
    STATE_IN_PROGRESS: <HourglassEmptyTwoTone style={{ color: '#8E8E8E' }} />,
} as const;

export type FileStatusType = {
    id: number;
    order: number;
    label: string;
    statusIcon: React.JSX.Element;
};

// see https://jiscdev.atlassian.net/wiki/spaces/JDF/pages/2032074846/Submission+states
export const FILE_STATUS: { [key: string]: FileStatusType } = {
    UNKNOWN: { id: 0, order: 0, label: 'Unknown', statusIcon: <Cached /> },
    UPLOADING: {
        id: 1,
        order: 1,
        label: 'Uploading',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    UPLOADED: {
        id: 2,
        order: 2,
        label: 'Uploaded',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    UPLOAD_ERROR: {
        id: 3,
        order: 3,
        label: 'Upload error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    PROVISIONING: {
        id: 4,
        order: 4,
        label: 'Provisioning',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    PROVISIONING_ERROR: {
        id: 5,
        order: 5,
        label: 'Provisioning error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    PROVISIONED: {
        id: 6,
        order: 6,
        label: 'Provisioned',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    SCHEMA_CHECK: {
        id: 7,
        order: 7,
        label: 'Schema check',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    SCHEMA_CHECK_FAILED: {
        id: 8,
        order: 8,
        label: 'Schema check failed',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    SCHEMA_CHECK_ERROR: {
        id: 9,
        order: 9,
        label: 'Schema check error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    SCHEMA_CHECK_PASSED: {
        id: 10,
        order: 10,
        label: 'Schema check passed',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    RIGHT_TO_PROCESS: {
        id: 37,
        order: 11,
        label: 'Right to process check',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    RIGHT_TO_PROCESS_FAILED: {
        id: 38,
        order: 12,
        label: 'Right to process check failed',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    RIGHT_TO_PROCESS_ERROR: {
        id: 39,
        order: 13,
        label: 'Right to process check error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    RIGHT_TO_PROCESS_PASSED: {
        id: 40,
        order: 14,
        label: 'Right to process check passed',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    ENRICHMENT: {
        id: 11,
        order: 15,
        label: 'Enrichment',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    ENRICHMENT_ERROR: {
        id: 12,
        order: 16,
        label: 'Enrichment error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    ENRICHMENT_PASSED: {
        id: 13,
        order: 17,
        label: 'Enrichment performed',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    SAVING_ENTITIES_ERROR: {
        id: 14,
        order: 18,
        label: 'Processing error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    QUALITY_RULES: {
        id: 15,
        order: 19,
        label: 'Quality rules',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    QUALITY_RULES_ERROR: {
        id: 16,
        order: 20,
        label: 'Quality rules error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    QUALITY_RULES_PASSED: {
        id: 17,
        order: 21,
        label: 'Quality reports generated',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    CREDIBILITY: {
        id: 18,
        order: 22,
        label: 'Credibility reports',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    CREDIBILITY_ERROR: {
        id: 19,
        order: 23,
        label: 'Credibility reports error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    CREDIBILITY_PASSED: {
        id: 20,
        order: 24,
        label: 'Credibility reports generated',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    COLLECTION_REPORTS: {
        id: 21,
        order: 25,
        label: 'Generating additional collection reports',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    COLLECTION_REPORTS_ERROR: {
        id: 22,
        order: 26,
        label: 'Additional collection reports generation error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    COLLECTION_REPORTS_PASSED: {
        id: 23,
        order: 27,
        label: 'Additional collection reports generated',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    QUALITY_PROCESSING_COMPLETE: {
        id: 24,
        order: 28,
        label: 'Quality processing complete',
        statusIcon: STATUS_ICONS.STATE_USER_ACTION_NEEDED,
    },
    HESA_ANALYST_APPROVAL: {
        id: 25,
        order: 29,
        label: 'Awaiting HESA analyst approval',
        statusIcon: STATUS_ICONS.STATE_USER_ACTION_NEEDED,
    },
    HESA_APPROVAL: {
        id: 26,
        order: 30,
        label: 'Awaiting HESA approval',
        statusIcon: STATUS_ICONS.STATE_USER_ACTION_NEEDED,
    },
    SC_APPROVAL: {
        id: 27,
        order: 31,
        label: 'Awaiting SC approval',
        statusIcon: STATUS_ICONS.STATE_USER_ACTION_NEEDED,
    },
    RESUBMISSION_REQUIRED: {
        id: 34,
        order: 32,
        label: 'Resubmission required',
        statusIcon: STATUS_ICONS.STATE_USER_ACTION_NEEDED,
    },
    APPROVED: {
        id: 28,
        order: 33,
        label: 'Approved',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    PROVIDER_SIGNOFF: {
        id: 29,
        order: 34,
        label: 'Awaiting provider sign off',
        statusIcon: STATUS_ICONS.STATE_USER_ACTION_NEEDED,
    },
    SIGNED_OFF: {
        id: 30,
        order: 35,
        label: 'Signed off',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    HISTORY_DATA: {
        id: 31,
        order: 36,
        label: 'Generating history',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    HISTORY_DATA_ERROR: {
        id: 32,
        order: 37,
        label: 'History generation error',
        statusIcon: STATUS_ICONS.STATE_FAILED,
    },
    HISTORY_DATA_PASSED: {
        id: 33,
        order: 38,
        label: 'History generation complete',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    NIL_RETURN: {
        id: 35,
        order: 39,
        label: 'Nil return',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
    RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT: {
        id: 36,
        order: 40,
        label: 'Resubmission required - historic amendment',
        statusIcon: STATUS_ICONS.STATE_CAN_PROGRESS,
    },
} as const;

export const fileStatusById = (id: number) =>
    Object.values(FILE_STATUS).find(fileStatus => fileStatus.id === id) ||
    FILE_STATUS.UNKNOWN;

export const fileStatusByKey = (key: string) =>
    key in FILE_STATUS ? FILE_STATUS[key] : FILE_STATUS.UNKNOWN;

export const ifStatusIdIsInProcessing = (statusId: number) =>
    [
        FILE_STATUS.PROVISIONING.id,
        FILE_STATUS.PROVISIONING_ERROR.id,
        FILE_STATUS.PROVISIONED.id,
        FILE_STATUS.SCHEMA_CHECK.id,
        FILE_STATUS.SCHEMA_CHECK_FAILED.id,
        FILE_STATUS.SCHEMA_CHECK_ERROR.id,
        FILE_STATUS.SCHEMA_CHECK_PASSED.id,
        FILE_STATUS.RIGHT_TO_PROCESS.id,
        FILE_STATUS.RIGHT_TO_PROCESS_FAILED.id,
        FILE_STATUS.RIGHT_TO_PROCESS_ERROR.id,
        FILE_STATUS.RIGHT_TO_PROCESS_PASSED.id,
        FILE_STATUS.ENRICHMENT.id,
        FILE_STATUS.ENRICHMENT_ERROR.id,
        FILE_STATUS.ENRICHMENT_PASSED.id,
        FILE_STATUS.SAVING_ENTITIES_ERROR.id,
        FILE_STATUS.QUALITY_RULES.id,
        FILE_STATUS.QUALITY_RULES_ERROR.id,
        FILE_STATUS.QUALITY_RULES_PASSED.id,
        FILE_STATUS.CREDIBILITY.id,
        FILE_STATUS.CREDIBILITY_ERROR.id,
        FILE_STATUS.CREDIBILITY_PASSED.id,
        FILE_STATUS.COLLECTION_REPORTS.id,
        FILE_STATUS.COLLECTION_REPORTS_ERROR.id,
        FILE_STATUS.COLLECTION_REPORTS_PASSED.id,
    ].includes(statusId);

export const ifStatusIdIsUpToQualityProcessingComplete = (statusId: number) =>
    [
        FILE_STATUS.UPLOADING.id,
        FILE_STATUS.UPLOADED.id,
        FILE_STATUS.UPLOAD_ERROR.id,
        FILE_STATUS.PROVISIONING.id,
        FILE_STATUS.PROVISIONING_ERROR.id,
        FILE_STATUS.PROVISIONED.id,
        FILE_STATUS.SCHEMA_CHECK.id,
        FILE_STATUS.SCHEMA_CHECK_FAILED.id,
        FILE_STATUS.SCHEMA_CHECK_ERROR.id,
        FILE_STATUS.SCHEMA_CHECK_PASSED.id,
        FILE_STATUS.RIGHT_TO_PROCESS.id,
        FILE_STATUS.RIGHT_TO_PROCESS_FAILED.id,
        FILE_STATUS.RIGHT_TO_PROCESS_ERROR.id,
        FILE_STATUS.RIGHT_TO_PROCESS_PASSED.id,
        FILE_STATUS.ENRICHMENT.id,
        FILE_STATUS.ENRICHMENT_ERROR.id,
        FILE_STATUS.ENRICHMENT_PASSED.id,
        FILE_STATUS.SAVING_ENTITIES_ERROR.id,
        FILE_STATUS.QUALITY_RULES.id,
        FILE_STATUS.QUALITY_RULES_ERROR.id,
        FILE_STATUS.QUALITY_RULES_PASSED.id,
        FILE_STATUS.CREDIBILITY.id,
        FILE_STATUS.CREDIBILITY_ERROR.id,
        FILE_STATUS.CREDIBILITY_PASSED.id,
        FILE_STATUS.COLLECTION_REPORTS.id,
        FILE_STATUS.COLLECTION_REPORTS_ERROR.id,
        FILE_STATUS.COLLECTION_REPORTS_PASSED.id,
        FILE_STATUS.QUALITY_PROCESSING_COMPLETE.id,
    ].includes(statusId);

export const ifStatusIdIsInApproval = (statusId: number) =>
    [
        FILE_STATUS.HESA_ANALYST_APPROVAL.id,
        FILE_STATUS.HESA_APPROVAL.id,
        FILE_STATUS.SC_APPROVAL.id,
        FILE_STATUS.RESUBMISSION_REQUIRED.id,
        FILE_STATUS.RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT.id,
    ].includes(statusId);

export const ifStatusIdAllowsResubmissionRequest = (statusId: number) =>
    [FILE_STATUS.SIGNED_OFF.id, FILE_STATUS.NIL_RETURN.id].includes(statusId);

export const ifStatusIdIsInSignOff = (statusId: number) =>
    [
        FILE_STATUS.APPROVED.id,
        FILE_STATUS.PROVIDER_SIGNOFF.id,
        FILE_STATUS.SIGNED_OFF.id,
    ].includes(statusId);
