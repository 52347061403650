import { useMutation } from '@tanstack/react-query';
import { submissions } from 'services/api';

export default function useUpdateSubmission() {
    const { mutate } = useMutation({
        mutationFn: submissions.updateSubmissionState,
    });

    return { updateState: mutate };
}
