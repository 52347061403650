import { CircularProgress } from '@mui/material';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './uploadProgress.module.scss';

export default function UploadProgress({ progress = 0 }) {
    return (
        <div className={styles.uploadProgress}>
            <CircularProgress
                variant="determinate"
                value={progress}
                size="4rem"
                aria-label="Circular loading animation"
            />
            <CircularProgress
                variant="determinate"
                value={100}
                sx={{ opacity: 0.25 }}
                size="4rem"
                aria-hidden="true"
                role="presentation"
                aria-label="Circular loading animation"
            />
            <div className={styles.label}>
                <p data-test-id={formatTestId('upload progress')}>
                    {progress}%
                </p>
            </div>
        </div>
    );
}
