import { useQuery } from '@tanstack/react-query';
import { GetSubmissionCommitAllowedStatusArgs } from 'services/api/submissions/types';

import { getUseCommitAllowedStatusOptions } from './options';

interface UseCommitAllowedStatusArgs
    extends GetSubmissionCommitAllowedStatusArgs {
    enabled?: boolean;
}

export default function useCommitAllowedStatus({
    enabled = true,
    ...args
}: UseCommitAllowedStatusArgs) {
    const options = getUseCommitAllowedStatusOptions(args);
    return useQuery({
        ...options,
        enabled,
    });
}
