import { FILE_STATUS } from 'src/constants/FileStatus';

import { StepperContextValue } from '../types';

import {
    currentStateIsFurtherOrEqualToFilter,
    currentStateMatchesFilters,
} from './utils';

const ACTIVE_STATES = [
    FILE_STATUS.UPLOADED,
    FILE_STATUS.PROVISIONING,
    FILE_STATUS.PROVISIONED,
    FILE_STATUS.SCHEMA_CHECK,
    FILE_STATUS.SCHEMA_CHECK_PASSED,
    FILE_STATUS.RIGHT_TO_PROCESS,
    FILE_STATUS.RIGHT_TO_PROCESS_PASSED,
    FILE_STATUS.ENRICHMENT,
    FILE_STATUS.ENRICHMENT_PASSED,
    FILE_STATUS.QUALITY_RULES,
    FILE_STATUS.QUALITY_RULES_PASSED,
    FILE_STATUS.CREDIBILITY,
    FILE_STATUS.CREDIBILITY_PASSED,
    FILE_STATUS.COLLECTION_REPORTS,
    FILE_STATUS.COLLECTION_REPORTS_PASSED,
];

const ERROR_STATES = [
    FILE_STATUS.PROVISIONING_ERROR,
    FILE_STATUS.CREDIBILITY_ERROR,
    FILE_STATUS.COLLECTION_REPORTS_ERROR,
    FILE_STATUS.SCHEMA_CHECK_ERROR,
    FILE_STATUS.SCHEMA_CHECK_FAILED,
    FILE_STATUS.RIGHT_TO_PROCESS_ERROR,
    FILE_STATUS.SAVING_ENTITIES_ERROR,
    FILE_STATUS.ENRICHMENT_ERROR,
    FILE_STATUS.QUALITY_RULES_ERROR,
];

const COMPLETE_STATE = FILE_STATUS.QUALITY_PROCESSING_COMPLETE;

const checkIfIsActive = (stepperContext: StepperContextValue) => {
    const { currentState } = stepperContext;
    return currentStateMatchesFilters(ACTIVE_STATES, currentState);
};

const checkIfIsError = (stepperContext: StepperContextValue) => {
    const { currentState } = stepperContext;

    return currentStateMatchesFilters(ERROR_STATES, currentState);
};

const checkIfIsComplete = (stepperContext: StepperContextValue) => {
    const { currentState } = stepperContext;
    return currentStateIsFurtherOrEqualToFilter(COMPLETE_STATE, currentState);
};

export default {
    checkIfIsActive,
    checkIfIsError,
    checkIfIsComplete,
};
