import { ReactNode } from 'react';

import styles from './bannerBox.module.scss';

type BannerBoxProps = {
    heading?: string;
    upperText: string;
    lowerText?: string | ReactNode;
};

const BannerBox = ({ heading, upperText, lowerText }: BannerBoxProps) => {
    return (
        <div className={styles.bannerBox}>
            {heading && <h2 className={styles.heading}>{heading}</h2>}
            <p className={styles.upperText}>{upperText}</p>
            <p>{lowerText}</p>
        </div>
    );
};

BannerBox.displayName = 'BannerBox';

export default BannerBox;
