import { useQuery } from '@tanstack/react-query';

import { getUseSubmissionOptions } from './options';

interface UseSubmissionArgs {
    submissionId: string;
    enabled?: boolean;
    isOvt?: boolean;
}

export default function useSubmission({
    enabled = true,
    submissionId,
    isOvt = false,
}: UseSubmissionArgs) {
    const options = getUseSubmissionOptions({ submissionId, isOvt });
    return useQuery({
        ...options,
        enabled,
    });
}
