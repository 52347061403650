import { delWithBody, get, post } from '../utils';

import {
    CancelUploadArgs,
    DownloadIrisOutputArgs,
    GetAcrCsvFileArgs,
    GetAllQualityRuleFailuresExistsResponse,
    UploadFileToS3Args,
    UploadSubmissionArgs,
    UploadSubmissionResponse,
} from './types';

let req: XMLHttpRequest;

// Generic
const getFileFromPresignedUrl = ({ url }: { url: string }) => {
    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const uploadRequest = ({
    submissionUuid,
    fileName,
    collectionId,
    instId,
    isOvt,
}: UploadSubmissionArgs) => {
    const endpoint = isOvt
        ? '/files/ovt/uploadRequest'
        : '/files/uploadRequest';
    return post(endpoint, {
        body: { submissionUuid, fileName, collectionId, instId },
        headers: {},
    }) as Promise<UploadSubmissionResponse>;
};

export const uploadFile = ({
    file,
    presignedPostData,
    xhr = new XMLHttpRequest(),
}: UploadFileToS3Args) => {
    req = xhr;
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);

    return new Promise((resolve, reject) => {
        req.open('POST', presignedPostData.url);

        req.addEventListener('load', () => {
            if (req.status >= 200 && req.status <= 300) {
                resolve(req.response);
            } else {
                reject({ status: req.status, statusText: req.statusText });
            }
        });

        req.addEventListener('error', () => {
            reject({
                status: req.status,
                statusText: req.statusText,
            });
        });

        req.send(formData);
    });
};

export const cancelUpload = async ({
    submissionUuid,
    collectionId,
    instId,
    fileName,
    isOvt = false,
}: CancelUploadArgs) => {
    const endppoint = isOvt
        ? '/files/ovt/cancel-upload'
        : '/files/cancel-upload';

    req?.abort();
    await post(endppoint, {
        body: {
            submissionUuid,
            instId,
            collectionId,
            fileName,
        },
        headers: {},
    });
};

export const downloadRequest = (path: string) =>
    post(`/files/downloadRequest`, {
        body: { key: path },
        headers: {},
    }) as Promise<{
        data: string;
    }>;

export const ovtDownloadRequest = (path: string) =>
    post(`/files/ovt/downloadRequest`, {
        body: { key: path },
        headers: {},
    }) as Promise<{
        data: string;
    }>;

// Quality Rules
export const downloadAllQualityRuleFailures = (submissionUuid: string) =>
    post(`/files/download-all-quality-rule-failures`, {
        body: { submissionUuid },
        headers: {},
    }) as Promise<{ url: string }>;

export const ovtDownloadAllQualityRuleFailures = (submissionUuid: string) =>
    post(`/files/ovt/download-all-quality-rule-failures`, {
        body: { submissionUuid },
        headers: {},
    }) as Promise<{ url: string }>;

export const getAllQualityRuleFailuresExists = (submissionUuid: string) =>
    get(`/files/all-quality-rule-failures-exists`, {
        query: { submissionUuid },
    }) as Promise<GetAllQualityRuleFailuresExistsResponse>;

export const ovtGetAllQualityRuleFailuresExists = (submissionUuid: string) =>
    get(`/files/ovt/all-quality-rule-failures-exists`, {
        query: { submissionUuid },
    }) as Promise<GetAllQualityRuleFailuresExistsResponse>;

// Delivery
export const downloadDelivery = (deliveryId: number, path: string) =>
    post(`/files/downloadDelivery`, {
        body: { deliveryId, key: path },
        headers: {},
    }) as Promise<{ data: string }>;

export const downloadSubmissionDelivery = (uuid: string, key: string | null) =>
    post(`/files/downloadSubmissionDelivery`, {
        body: { uuid, key },
        headers: {},
    }) as Promise<{ data: string }>;

export const deleteDelivery = (deliveryId: number, path: string) =>
    delWithBody(`/files/deleteDelivery`, { body: { deliveryId, key: path } });

export const downloadIrisOutput = ({ key, uuid }: DownloadIrisOutputArgs) =>
    post(`/files/downloadIrisOutput`, {
        body: { key, uuid },
        headers: {},
    }) as Promise<{ data: string }>;

// Sign-off
export const signOffFormDownloadRequest = (hash: string) =>
    post(`/files/signOffFormDownloadRequest/${hash}`) as Promise<{
        data: string;
    }>;

// Additional Collection Reports
export const getAcrCsvFile = async ({
    submissionUuid,
    reportCode,
    fileCode,
}: GetAcrCsvFileArgs) => {
    const res = (await post(`/files/acr/url/${reportCode.toLowerCase()}/csv`, {
        body: { submissionUuid, reportCode, fileCode },
        headers: {},
    })) as { url: string };

    getFileFromPresignedUrl(res);
};

// Graceful Failures
export const downloadGracefulFailures = (submissionUuid: string) =>
    post(`/files/download-graceful-failures`, {
        body: { submissionUuid },
        headers: {},
    }) as Promise<{ url: string }>;

export const ovtDownloadGracefulFailures = (submissionUuid: string) =>
    post(`/files/ovt/download-graceful-failures`, {
        body: { submissionUuid },
        headers: {},
    }) as Promise<{ url: string }>;
