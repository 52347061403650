import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { usePageTitle } from 'components';
import { PageTitle } from 'components';
import { Collection } from 'services/api/collections/types';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import useCollections from 'src/queries/collections/useCollections';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CollectionsTableRow from './CollectionTableRow';

import styles from './collections.module.scss';

const Loading = () => {
    return (
        <Box className={styles.box}>
            <CircularProgress aria-label="Circular loading animation" />
        </Box>
    );
};

interface CollectionsTableProps {
    collections: Collection[];
}

const CollectionsTable = ({ collections }: CollectionsTableProps) => {
    return (
        <Box mb={4}>
            <TableContainer component={Paper}>
                <Table data-test-id={formatTestId('table', 'collections')}>
                    <TableHead>
                        <TableRow className={styles.header}>
                            <TableCell className={styles.headerCell}>
                                Reference
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Name
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Reference Period
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                State
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collections.map((collection, _index) => (
                            <CollectionsTableRow
                                key={collection.reference}
                                collection={collection}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const states = {
    LOADING: 'pending',
    LOADED: 'success',
    LOADING_FAILED: 'error',
};

const ErrorMessage = ({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) => (
    <Container maxWidth={'sm'}>
        <Typography variant={'h6'} align={'left'} gutterBottom>
            {title}
        </Typography>
        {children}
    </Container>
);

export const Collections = () => {
    usePageTitle('Monitoring');

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
    };

    const tableId = TABLE_PARAMS_IDS.COLLECTIONS;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { offset, limit } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const { data, status } = useCollections({
        limit: limit,
        offset: offset * limit,
    });

    const pagingMetadata = data?.pagingMetadata || { total: 0 };

    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ) => {
        setOffset(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(Number(event.target.value));

        if (Number(event.target.value) > pagingMetadata.total) {
            setOffset(0);
        }
    };

    const handleTryAgain = () => {
        // Refresh the page so that the data is fetched again
        window.location.reload();
    };

    const renderState = () => {
        switch (status) {
            case states.LOADING:
                return <Loading />;

            case states.LOADED:
                return (
                    <>
                        {data?.collections && (
                            <CollectionsTable collections={data.collections} />
                        )}{' '}
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={pagingMetadata.total}
                            rowsPerPage={limit}
                            page={offset}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                );

            case states.LOADING_FAILED:
                return (
                    <ErrorMessage title={'Loading Error'}>
                        <Typography paragraph align={'left'}>
                            The list of collections failed to load.
                        </Typography>
                        <Button
                            variant={'outlined'}
                            onClick={handleTryAgain}
                            data-test-id={formatTestId('try again')}
                        >
                            Try again
                        </Button>
                    </ErrorMessage>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <PageTitle title="Monitoring" />
            <Box marginTop={3}>{renderState()}</Box>
        </>
    );
};

export default Collections;
