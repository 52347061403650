import { useQuery } from '@tanstack/react-query';

import { getUseCollectionsQueryOptions } from './options';
import { CollectionsQueryOptions } from './types';

export default function useCollections(queryOptions: CollectionsQueryOptions) {
    const options = getUseCollectionsQueryOptions(queryOptions);

    return useQuery({
        ...options,
        select: data => data,
    });
}
