import { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Button, Paper } from '@mui/material';
import useUploadSubmission from 'queries/files/useUploadSubmission';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import { RestrictAccess } from 'src/components/index';

import { SubmissionOutletContextValue } from '../../types';
import { useStepper } from '../Stepper/StepperContext';

import UploadedMessage from './UploadedMessage';
import UploadError from './UploadError';
import UploadForm from './UploadForm';
import UploadingMessage from './UploadingMessage';

import styles from './upload.module.scss';

const Upload = ({ isOvt = false }) => {
    const xhrRef = useRef<XMLHttpRequest>(new XMLHttpRequest());
    const [file, setFile] = useState<File>();
    const [fileName, setFileName] = useState('');
    const { navigateToStep, steps } = useStepper();
    const { submissionId } = useParams();
    const [isValidating, setIsValidating] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const intervalRef = useRef<number>();
    const { instId, data, collectionId, setPageTitle, getSubmission } =
        useOutletContext<SubmissionOutletContextValue>();

    const { uploadFile } = useUploadSubmission();

    useEffect(() => {
        setPageTitle('Upload');
    }, [setPageTitle]);

    const isUploaded = steps?.some(
        step => step.label === 'Upload' && step.isComplete,
    );

    const hasError = steps?.some(
        step => step.label === 'Upload' && step.isError,
    );

    useEffect(() => {
        xhrRef.current.upload.onprogress = event => {
            setUploadProgress(Math.round(event.loaded / event.total) * 100);
        };
    }, []);

    useEffect(() => {
        if (shouldRefetch && !intervalRef.current) {
            // Refetch until the submission state updates.
            intervalRef.current = setInterval(() => getSubmission(), 1000);
        }
    }, [getSubmission, shouldRefetch]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const handleUpload = () => {
        if (isUploaded) {
            navigateToStep('Processing');
            return;
        } else if (
            file === undefined ||
            submissionId === undefined ||
            collectionId === null ||
            instId === undefined
        ) {
            return;
        } else {
            setIsUploading(true);
            uploadFile(
                {
                    submissionUuid: submissionId,
                    file: file,
                    fileName,
                    collectionId,
                    instId: instId!,
                    isOvt,
                    xhr: xhrRef.current,
                },
                {
                    onSuccess: () => {
                        setShouldRefetch(true);
                    },
                    onSettled: () => {
                        getSubmission();
                    },
                },
            );
        }
    };

    const uploadButtonDisabled =
        (!isUploaded && !fileName) ||
        isValidating ||
        hasValidationError ||
        isUploading;

    const UploadContent = useMemo(() => {
        if (hasError) {
            return <UploadError />;
        }

        if (isUploaded) {
            return (
                <UploadedMessage instId={instId!} data={data!} isOvt={isOvt} />
            );
        }

        if (isUploading) {
            return (
                <UploadingMessage
                    submissionUuid={data?.uuid || submissionId!}
                    instId={instId!}
                    collectionId={collectionId!}
                    fileName={data?.fileName || fileName!}
                    uploadProgress={uploadProgress}
                    isOvt={isOvt}
                />
            );
        }

        return (
            <UploadForm
                setFile={setFile}
                setFileName={setFileName}
                fileName={fileName}
                isValidating={isValidating}
                setIsValidating={setIsValidating}
                hasValidationError={hasValidationError}
                setHasValidationError={setHasValidationError}
            />
        );
    }, [
        hasError,
        isUploaded,
        isUploading,
        fileName,
        isValidating,
        hasValidationError,
        instId,
        data,
        isOvt,
        submissionId,
        collectionId,
        uploadProgress,
    ]);

    return (
        /* @ts-expect-error - RestrictAccess is not typed */
        <RestrictAccess
            allowPermissions={
                !isOvt
                    ? ['collections.ingestion']
                    : ['online-validation.ingestion-ovt']
            }
            render={() => <AccessDenied mt={8} />}
        >
            <Paper className={styles.upload}>{UploadContent}</Paper>
            <div className={styles.buttons}>
                <Button
                    className="hdp-override large"
                    data-test="Upload"
                    disabled={uploadButtonDisabled}
                    disableRipple
                    variant="contained"
                    onClick={handleUpload}
                    data-test-id={
                        isUploaded || isUploading ? 'continue' : 'upload'
                    }
                >
                    {isUploaded || isUploading ? 'Continue' : 'Upload'}
                </Button>
                <Button
                    className="hdp-override large hdp-override--grey"
                    data-test-id="back"
                    disableRipple
                    variant="contained"
                    onClick={() => navigateToStep('Submissions')}
                >
                    Back
                </Button>
            </div>
        </RestrictAccess>
    );
};

export default Upload;
