import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import { ReactComponent as FileExclamation } from 'assets/icons/file_exclamation.svg';
import { useUserContext } from 'components';
import { DateTime } from 'luxon';
import { FILE_STATUS, fileStatusByKey } from 'src/constants/FileStatus';
import { getSubmissionLinkForStep } from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { requestGracefulFailuresDownload } from '../utils';

import AwaitingActionMenu from './AwaitingActionMenu';

import styles from './submissionSummaryTable.module.scss';

const formatDate = date => DateTime.fromISO(date).toFormat('dd/LL/yyyy HH:mm');

const getStepNumber = submission => submission?.status?.step?.number;

const headCells = [
    { id: 'id', label: 'ID', sortable: false },
    { id: 'provider', label: 'Provider', sortable: false },
    { id: 'current-state', label: 'Current submission state', sortable: false },
    {
        id: 'current-step',
        label: 'Current step',
        sortable: true,
        customClass: styles.sort,
    },
    {
        id: 'furthest-step',
        label: 'Furthest step',
        sortable: true,
        customClass: styles.sort,
    },
    {
        id: 'last-submission',
        label: 'Last submission',
        sortable: true,
        customClass: styles.sort,
    },
    { id: 'student-count', label: 'Student count', sortable: false },
    { id: 'open-issues', label: 'Open issues', sortable: false },
    { id: 'awaiting-action', label: 'Awaiting action', sortable: false },
];

export const SubmissionLink = ({
    collection,
    submission,
    isNilReturn,
    institutionId,
    isHistoricResubmission,
    isOvt,
}) => {
    const { statutoryCustomer: isStatutoryCustomer } = useUserContext();

    if (!submission) return 'Not started';
    if (isNilReturn) {
        if (isHistoricResubmission) {
            return 'Resubmission required - historic amendment (Nil return)';
        } else return 'Nil return';
    }

    const { adminPath } = getSubmissionLinkForStep({
        submissionId: submission.uuid,
        reference: collection?.reference,
        collectionId: collection?.id,
        instId: institutionId,
        status: submission.status.code,
        isOvt,
        isStatutoryCustomer,
    });

    return (
        <Link
            className={styles.link}
            data-test-id={formatTestId(
                'link to',

                `${isOvt ? 'ovt ' : ''}submission state ${institutionId}`,
            )}
            to={{
                pathname: adminPath,
                state: {
                    name: collection.name,
                    reference: collection.reference,
                },
            }}
        >
            {submission?.status?.name}
        </Link>
    );
};

const SubmissionSummaryTableRow = ({
    provider,
    collection,
    latestSubmission,
    furthestSubmission,
    totalSteps,
    awaitingParty,
    isOvt,
    className,
    actionStates,
}) => {
    const stateHistory = latestSubmission?.stateHistory.map(fileStatusByKey);

    const isNilReturn = stateHistory?.includes(FILE_STATUS.NIL_RETURN);
    const isHistoricResubmission = stateHistory?.includes(
        FILE_STATUS.RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT,
    );

    const currentStep = getStepNumber(latestSubmission);
    const furthestStep = getStepNumber(furthestSubmission);
    const issueCount = latestSubmission ? provider.issueCount : '';

    const shouldShowMoreActions = useMemo(
        () => Object.values(actionStates).some(state => state),
        [actionStates],
    );

    const GracefulFailuresDownload = ({ latestSubmission }) => {
        const { roles } = useUserContext();
        const isAdmin = roles.includes('admin');

        if (isAdmin && latestSubmission?.errorFileExists) {
            return (
                <div className={styles.iconWrapper}>
                    <Tooltip
                        title="Download error log file"
                        arrow
                        placement={'top'}
                    >
                        <button
                            onClick={() =>
                                requestGracefulFailuresDownload(
                                    latestSubmission.uuid,
                                )
                            }
                            className="hdp-text-btn--regular"
                            data-test-id={formatTestId(
                                'download file',
                                'graceful failures report',
                            )}
                            aria-label="Download Graceful Failures Report"
                        >
                            <FileExclamation className={styles.icon} />
                        </button>
                    </Tooltip>
                </div>
            );
        }
    };

    return (
        <TableRow hover className={className}>
            <TableCell>{provider.institutionId}</TableCell>
            <TableCell>{provider.name}</TableCell>
            <TableCell className={styles.multiLinkWrapper}>
                <SubmissionLink
                    collection={collection}
                    submission={latestSubmission}
                    institutionId={provider.institutionId}
                    providerName={provider.name}
                    isNilReturn={isNilReturn}
                    isHistoricResubmission={isHistoricResubmission}
                    isOvt={isOvt}
                />
                <GracefulFailuresDownload latestSubmission={latestSubmission} />
            </TableCell>
            {!isOvt ? (
                <>
                    <TableCell>
                        {currentStep && `${currentStep}/${totalSteps}`}
                    </TableCell>
                    <TableCell>
                        {furthestStep && `${furthestStep}/${totalSteps}`}
                    </TableCell>
                    <TableCell>
                        {latestSubmission?.uploaded
                            ? formatDate(latestSubmission?.uploaded)
                            : ''}
                    </TableCell>
                    <TableCell>
                        {isNilReturn ? 0 : latestSubmission?.studentCount}
                    </TableCell>
                    <TableCell>{isNilReturn ? 0 : issueCount}</TableCell>
                    <TableCell sx={{ position: 'relative' }}>
                        {awaitingParty?.name}
                        {shouldShowMoreActions && (
                            <AwaitingActionMenu
                                latestSubmission={latestSubmission}
                                provider={provider}
                                collection={collection}
                                actionStates={actionStates}
                            />
                        )}
                    </TableCell>
                </>
            ) : (
                <TableCell>{formatDate(latestSubmission.uploaded)}</TableCell>
            )}
        </TableRow>
    );
};

const SubmissionSummaryTableHead = ({
    orderBy,
    order,
    onSort,
    id,
    label,
    sortable,
    customClass,
}) => {
    if (!sortable) {
        return <TableCell key={id}>{label}</TableCell>;
    }

    return (
        <TableCell key={id} className={customClass}>
            <TableSortLabel
                active={id === orderBy}
                direction={id === orderBy ? order : 'desc'}
                onClick={() => onSort(id)}
                className={customClass}
                data-test-id={formatTestId('sort submissions summary', label)}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    );
};

const SubmissionSummaryTableBody = ({
    submissions,
    isOvt,
    pagedSubmissions,
}) => {
    if (submissions.length === 0) {
        return (
            <TableBody>
                <TableRow className={styles.row}>
                    <TableCell align={'center'} colSpan={9}>
                        <Box p={1}>
                            No submissions found that match your criteria
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    const key = submission =>
        `${submission.provider.institutionId}-${submission.collection.reference}`;

    return (
        <TableBody className="hdp-override--body-cell">
            {pagedSubmissions.map(submission => (
                <SubmissionSummaryTableRow
                    className={styles.row}
                    key={key(submission)}
                    {...submission}
                    isOvt={isOvt}
                />
            ))}
        </TableBody>
    );
};

export const SubmissionSummaryTable = props => {
    const { submissions, isOvt, limit, setLimit, offset, setOffset } = props;

    // required for pagination
    const rowCount = submissions.length;

    const numberOfPages = Math.ceil(rowCount / limit) || 1;

    useEffect(() => {
        if (offset >= numberOfPages) {
            setOffset(numberOfPages - 1);
        }
    }, [numberOfPages, offset, setOffset]);

    const handleChangeRowsPerPage = event => {
        const rows = +event.target.value;
        setLimit(rows);
        setOffset(0);
    };

    const handleChangePage = (event, newPage) => {
        setOffset(newPage);
    };

    // prettier-ignore
    const pagedSubmissions = submissions.slice(
    (offset * limit),
    ((offset * limit) + limit)
);

    return (
        <TableContainer style={{ overflowX: 'auto' }}>
            <Table
                size={'small'}
                data-test-id={formatTestId('table', 'submission summary')}
            >
                <TableHead>
                    <TableRow className={styles.header}>
                        {headCells.map(headCell => (
                            <SubmissionSummaryTableHead
                                key={headCell.id}
                                {...headCell}
                                {...props}
                            />
                        ))}
                    </TableRow>
                </TableHead>
                <SubmissionSummaryTableBody
                    submissions={submissions}
                    isOvt={isOvt}
                    pagedSubmissions={pagedSubmissions}
                />
            </Table>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rowCount || 0}
                rowsPerPage={limit}
                page={offset}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                    'data-test-id': formatTestId(
                        'previous page',
                        'submissions summary',
                    ),
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                    'data-test-id': formatTestId(
                        'next page',
                        'submissions summary',
                    ),
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

SubmissionSummaryTable.displayName = 'SubmissionSummaryTable';
