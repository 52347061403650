import { useMutation, useQueryClient } from '@tanstack/react-query';
import { files } from 'services/api';

interface HandleUploadFileArgs {
    file: File;
    submissionUuid: string;
    collectionId: string;
    fileName: string;
    instId: string;
    isOvt?: boolean;
    xhr: XMLHttpRequest;
}

const handleUploadFile = async ({
    file,
    submissionUuid,
    collectionId,
    fileName,
    instId,
    isOvt = false,
    xhr,
}: HandleUploadFileArgs) => {
    // Get presigned post data to use for uploading to S3
    const { data: presignedPostData } = await files.uploadRequest({
        submissionUuid,
        fileName,
        collectionId,
        instId,
        isOvt,
    });

    await files.uploadFile({
        file,
        presignedPostData,
        xhr,
    });
};

export default function useUploadSubmission() {
    const queryClient = useQueryClient();
    const { mutate: uploadFile } = useMutation({
        mutationFn: handleUploadFile,
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: ['submssions'],
            });
        },
    });

    const { mutate: cancelUpload } = useMutation({
        mutationFn: files.cancelUpload,
    });

    return { uploadFile, cancelUpload };
}
