import { Link } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import { collectionStates } from 'constants/collections';
import { DateTime } from 'luxon';
import { Collection } from 'services/api/collections/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './collections.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy');

interface CollectionStatusProps {
    id: number;
}

interface CollectionsTableRowProps {
    collection: Collection;
}

const CollectionStatus = ({ id }: CollectionStatusProps) => {
    switch (id) {
        case collectionStates.DRAFT:
            return <span className={styles.collectionState}>Draft</span>;

        case collectionStates.DELETED:
            return <span className={styles.collectionState}>Deleted</span>;

        case collectionStates.OPEN:
            return <span className={styles.collectionState}>Open</span>;

        case collectionStates.CLOSED:
            return <span className={styles.collectionState}>Closed</span>;

        case collectionStates.HISTORIC_AMENDMENT:
            return (
                <span className={styles.collectionState}>
                    Historic Amendment
                </span>
            );

        case collectionStates.ARCHIVED:
            return <span className={styles.collectionState}>Archived</span>;

        case collectionStates.VALIDATION:
            return <span className={styles.collectionState}>Validation</span>;

        default:
            return <span className={styles.collectionState}>Unassigned</span>;
    }
};

const CollectionsTableRow = ({ collection }: CollectionsTableRowProps) => {
    const {
        id: collectionId,
        name,
        referencePeriodStart,
        referencePeriodEnd,
        reference,
    } = collection;

    const { id: collectionStatusId } = collection.state;

    const start = formatDate(referencePeriodStart);
    const end = formatDate(referencePeriodEnd);

    return (
        <TableRow hover className={styles.collectionItem}>
            <TableCell className={styles.collectionReference}>
                {reference}
            </TableCell>
            <TableCell className={styles.collectionName}>
                {collectionId ? (
                    <Link
                        className={styles.link}
                        to={{
                            pathname: `/monitoring/${reference}`,
                        }}
                        state={{
                            id: collectionId,
                            reference,
                            name: collection.name,
                        }}
                        data-test-id={formatTestId('link to', reference)}
                    >
                        {name}
                    </Link>
                ) : (
                    name
                )}
            </TableCell>
            <TableCell className={styles.collectionReference}>
                {start} - {end}
            </TableCell>
            <TableCell>
                <CollectionStatus id={collectionStatusId} />
            </TableCell>
        </TableRow>
    );
};

export default CollectionsTableRow;
