import * as filesApi from '../../../services/api/files';

export const requestGracefulFailuresDownload = async submissionUuid => {
    const { url } = await filesApi.downloadGracefulFailures(submissionUuid);
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};
