import { DateTime } from 'luxon';
import { requestOvtFileDownload } from 'pages/Collections/OnlineValidation/utils';
import { Submission } from 'types/submission';

import { requestFileDownload } from '../../utils';

import styles from './upload.module.scss';

interface UploadedMessageProps {
    instId: string;
    data: Submission;
    isOvt?: boolean;
}

export default function UploadedMessage({
    instId,
    data,
    isOvt = false,
}: UploadedMessageProps) {
    return (
        <>
            <div className={styles.uploadActions}>
                <p>
                    File <strong>{data.fileName}</strong> was uploaded on{' '}
                    {DateTime.fromISO(data.uploaded).toLocaleString(
                        DateTime.DATETIME_MED,
                    )}
                </p>
                <p>
                    <button
                        className={styles.linkButton}
                        onClick={() => {
                            !isOvt
                                ? requestFileDownload(
                                      data.uuid,
                                      instId,
                                      data.fileName,
                                      data.uploaded,
                                      false,
                                  )
                                : requestOvtFileDownload(
                                      data.uuid,
                                      instId,
                                      data.fileName,
                                      data.uploaded,
                                      false,
                                  );
                        }}
                    >
                        Download Original File
                    </button>
                </p>
            </div>
        </>
    );
}
