import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import QualityRule from 'components/QualityRule/QualityRule';
import useTableParams from 'hooks/useTableParams/useTableParams';
import { selectors as regulatorSelectors } from 'modules/regulators';
import {
    getValidationDetails,
    getValidationDetailsCsv,
    getValidationDetailsUri,
} from 'services/api/submissions';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';

import { requestQualityRuleFailuresDownload } from '../../utils';

const OvtQualityRule = () => {
    const { rule, submissionId, regulatorCode } = useParams();
    const { instId } = useOutletContext();
    const [validation, setValidation] = useState({});
    const [results, setResults] = useState([]);
    const [pageData, setPageData] = useState([]);
    const regulators = useSelector(regulatorSelectors.getRegulatorsByCode);
    const [csvData, setCsvData] = useState();
    const csvLink = useRef(null);
    const [isDownloading, setIsDownloading] = React.useState(false);

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
    };

    const tableId = TABLE_PARAMS_IDS.ONLINE_VALIDATION_QUALITYRULE;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    function handleChangeRowsPerPage(event) {
        setLimit(+event.target.value);
        setOffset(0);
    }

    function handleChangePage(event, newPage) {
        setOffset(newPage);
    }

    // useMemo here is required to prevent useEffect from being called on every render
    const regulator = useMemo(
        () => regulators[regulatorCode] || {},
        [regulatorCode, regulators],
    );

    useEffect(() => {
        const fetchValidations = async () => {
            const results = await getValidationDetails({
                submissionId,
                validationId: rule,
                instId,
                regulatorCode,
                offset: offset,
                limit: limit,
                ovt: true,
            });
            setResults(results.records);
            setPageData(results.pagingMetadata);

            setValidation(results.validation);
        };
        fetchValidations();
    }, [
        submissionId,
        rule,
        validation.Id,
        offset,
        limit,
        instId,
        regulator,
        regulatorCode,
        regulators,
    ]);

    const handleClickDownloadCsv = async () => {
        if (submissionId) {
            setIsDownloading(true);
            if (pageData.totalResultsCount > 1000) {
                const uri = await getValidationDetailsUri({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                    ovt: true,
                });

                await requestQualityRuleFailuresDownload(uri);
            } else {
                const csv = await getValidationDetailsCsv({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                    ovt: true,
                });

                setCsvData({
                    href: `data:text/csv;charset=utf-8,${encodeURIComponent(
                        csv,
                    )}`,
                    download: `QualityRuleReport.${submissionId.toLowerCase()}.${rule}.${new Date().toISOString()}.csv`,
                });
            }
            setIsDownloading(false);
        }
    };

    useEffect(() => {
        if (csvLink && csvData) {
            csvLink.current.click();
            setCsvData();
        }
    }, [csvLink, csvData]);

    return (
        <QualityRule
            isDownloading={isDownloading}
            rule={rule}
            regulator={regulator}
            results={results}
            validation={validation}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClickDownloadCsv={handleClickDownloadCsv}
            pageData={pageData}
            offset={offset}
            limit={limit}
            csvData={csvData}
            csvLink={csvLink}
        />
    );
};

export default OvtQualityRule;
