import {
    Children,
    cloneElement,
    forwardRef,
    useEffect,
    useId,
    useRef,
    useState,
} from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors, sizes } from 'src/styles/config/branding.config';

import {
    HDPAccordionActionsBase,
    HDPAccordionBase,
    HDPAccordionDetailsBase,
    HDPAccordionDetailsWrapper,
    HDPAccordionExpandIconBase,
    HDPAccordionGroupBase,
    HDPAccordionSummaryBase,
    HDPAccordionSummaryButtonBase,
} from './HDPAccordion.styles';

export const HDPAccordion = forwardRef((props, ref) => {
    const {
        children: baseChildren,
        className,
        disabled = false,
        isOpenByDefault = false,
        searchParamId,
        ...other
    } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const wrapperRef = useRef(ref);
    const detailsRef = useRef(null);
    const [isOpen, setIsOpen] = useState(
        isOpenByDefault || searchParams.get(searchParamId) === 'true',
    );

    useEffect(() => {
        const newSearchParams = createSearchParams(searchParams);

        if (isOpen) {
            newSearchParams.set(searchParamId, 'true');
        } else {
            newSearchParams.delete(searchParamId);
        }

        if (newSearchParams.toString() !== searchParams.toString()) {
            setSearchParams(newSearchParams, { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, searchParams]);

    /* Get the HDPAccordionSummary & Details child elements -
     * should always be the first two children, the rest populate
     * the HDPAccordionDetailsBase component.
     */
    const [summary, details, ...children] = Children.toArray(baseChildren);

    /* Enforce accessibility guidelines
     * https://www.w3.org/WAI/ARIA/apg/patterns/accordion/
     */

    const generatedAriaControlsId = useId();
    const generatedSummaryId = useId();
    const generatedAccordionId = useId();

    const ariaControlsId = summary.props['aria-controls']
        ? summary.props['aria-controls']
        : generatedAriaControlsId;
    const summaryId = summary.props['id']
        ? summary.props['id']
        : generatedSummaryId;
    const accordionId = details.props.id
        ? details.props.id
        : generatedAccordionId;

    return (
        <HDPAccordionBase
            className={className}
            expanded={isOpen}
            ref={wrapperRef}
            id={accordionId}
            {...other}
        >
            {summary &&
                cloneElement(summary, {
                    onClick: () => setIsOpen(!isOpen),
                    expanded: isOpen,
                    disabled,
                    ariaControls: ariaControlsId,
                    id: summaryId,
                })}
            {details &&
                cloneElement(
                    details,
                    { expanded: isOpen, detailsRef, ariaControlsId, summaryId },
                    ...children,
                )}
        </HDPAccordionBase>
    );
});

export const HDPAccordionSummary = forwardRef((props, ref) => {
    const {
        children,
        className,
        expandIcon,
        onClick,
        disabled,
        expanded,
        header,
        ariaControls,
        size,
        fontWeight,
        color,
        ...other
    } = props;

    return (
        <HDPAccordionSummaryBase
            className={className}
            size={size}
            fontWeight={fontWeight}
            color={color}
            aria-label={header}
        >
            <HDPAccordionSummaryButtonBase
                onClick={onClick}
                disabled={disabled}
                ref={ref}
                aria-expanded={expanded}
                aria-controls={ariaControls}
                {...other}
            >
                {expandIcon && (
                    <HDPAccordionExpandIconBase
                        disabled={disabled}
                        expanded={expanded}
                        {...expandIcon.props}
                    >
                        {expandIcon}
                    </HDPAccordionExpandIconBase>
                )}
                {header}
            </HDPAccordionSummaryButtonBase>
            <HDPAccordionActionsBase>{children}</HDPAccordionActionsBase>
        </HDPAccordionSummaryBase>
    );
});

export const HDPAccordionDetails = forwardRef((props, ref) => {
    const {
        children,
        className,
        expanded,
        detailsRef,
        ariaControlsId,
        summaryId,
        ...other
    } = props;

    return (
        <HDPAccordionDetailsWrapper
            ref={detailsRef}
            expanded={expanded}
            aria-labelledby={summaryId}
            id={ariaControlsId}
            role="region"
        >
            <HDPAccordionDetailsBase className={className} ref={ref} {...other}>
                {expanded && children}
            </HDPAccordionDetailsBase>
        </HDPAccordionDetailsWrapper>
    );
});

export const HDPAccordionGroup = forwardRef((props, ref) => {
    const { children, spread } = props;
    return (
        <HDPAccordionGroupBase as="section" ref={ref} spread={spread}>
            {Children.map(children, child => {
                return child && cloneElement(child, { spread });
            })}
        </HDPAccordionGroupBase>
    );
});

HDPAccordion.propTypes = {
    headerLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    children: PropTypes.arrayOf(PropTypes.element),
    isOpenByDefault: PropTypes.bool,
    disabled: PropTypes.bool,
    borderColor: PropTypes.oneOf(Object.keys(colors)),
    square: PropTypes.bool,
    noBorder: PropTypes.bool,
    size: PropTypes.oneOf(Object.keys(sizes)),
    background: PropTypes.string,
    elevated: PropTypes.bool,
    searchParamId: PropTypes.string.isRequired,
};

HDPAccordion.defaultProps = {
    headerLevel: 2,
    isOpenByDefault: false,
    disabled: false,
    elevated: false,
};

HDPAccordionSummary.propTypes = {
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
    size: PropTypes.oneOf([...Object.keys(sizes)]),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    color: PropTypes.string,
    expandIcon: PropTypes.element,
};

HDPAccordionDetails.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.node,
    ]),
};

HDPAccordionGroup.propTypes = {
    spread: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

HDPAccordionGroup.defaultProps = {
    spread: true,
};

HDPAccordion.displayName = 'HDPAccordion';
HDPAccordionSummary.displayName = 'HDPAccordionSummary';
HDPAccordionDetails.displayName = 'HDPAccordionDetails';
HDPAccordionGroup.displayName = 'HDPAccordionGroup';
