import { useQuery } from '@tanstack/react-query';
import { GetSchemaChecksArgs } from 'services/api/submissions/types';

import { getUseSchemaChecksOptions } from './options';

interface UseSchemaChecksArgs extends GetSchemaChecksArgs {
    enabled?: boolean;
}

export default function useSchemaChecks({
    enabled = true,
    ...args
}: UseSchemaChecksArgs) {
    const options = getUseSchemaChecksOptions(args);
    return useQuery({
        ...options,
        enabled,
    });
}
