import { CircularProgress, List, ListItem } from '@mui/material';
import { FILE_STATUS } from 'constants/FileStatus';
import useSubmissions from 'src/queries/submissions/useSubmissions';
import { Collection } from 'src/types/collection';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import SubmissionLink from './SubmissionLink';
import { generateUploadText } from './utils';

import styles from './submissions.module.scss';

interface SubmissionsListProps {
    collection: Collection;
    instId: string;
    isOvt?: boolean;
}

export default function SubmissionsList({
    collection,
    instId,
    isOvt = false,
}: SubmissionsListProps) {
    const {
        data: submissions,
        isLoading,
        isError,
    } = useSubmissions({
        collectionId: collection.id,
        instId,
        isOvt,
    });

    if (isError) {
        return <p>Error getting submissions</p>;
    }

    if (isLoading || submissions === undefined) {
        return <CircularProgress aria-label="Circular loading animation" />;
    }

    if (submissions?.length === 0) {
        return <p>No Submissions found for this collection</p>;
    }

    const listOfSubmissions = isOvt ? [submissions.at(0)!] : submissions;

    return (
        <div>
            <div className={styles.header}>
                <span className={`${styles.heading} ${styles.submission}`}>
                    Submission
                </span>
                <span className={`${styles.heading} ${styles.uploaded}`}>
                    Uploaded
                </span>
                <span className={`${styles.heading} ${styles.status}`}>
                    Status
                </span>
            </div>
            <List className={styles.submissionsList}>
                {Array.isArray(listOfSubmissions) &&
                    listOfSubmissions.map((submission, index) => (
                        <ListItem
                            key={submission.Uuid}
                            className={styles.submissionItem}
                        >
                            <SubmissionLink
                                submission={submission}
                                index={index}
                                isOvt={isOvt}
                            />
                            <span className={styles.uploaded}>
                                {generateUploadText(submission, submissions)}
                            </span>
                            <span
                                className={`${styles.detail} ${styles.detailStatus}`}
                                data-test-id={formatTestId(
                                    'submission status',
                                    `submission ${submission.SubmissionId}`,
                                )}
                            >
                                <span className={styles.icon}>
                                    {FILE_STATUS[submission.Status]
                                        ? FILE_STATUS[submission.Status]
                                              .statusIcon
                                        : ''}
                                </span>
                                <span>
                                    {FILE_STATUS[submission.Status]
                                        ? FILE_STATUS[submission.Status].label
                                        : ''}
                                </span>
                            </span>
                        </ListItem>
                    ))}
            </List>
        </div>
    );
}
