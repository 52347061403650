import { FILE_STATUS } from 'src/constants/FileStatus';

import { StepperContextValue } from '../types';

import {
    currentStateMatchesFilters,
    stateHistoryIncludesFilters,
} from './utils';

const ACTIVE_STATES = [FILE_STATUS.UNKNOWN, FILE_STATUS.UPLOADING];
const ERROR_STATES = [FILE_STATUS.UPLOAD_ERROR];
const COMPLETE_STATES = [FILE_STATUS.UPLOADED, FILE_STATUS.PROVISIONING];

const checkIfIsActive = (stepperContext: StepperContextValue) => {
    const { currentState } = stepperContext;
    // if currentState is `undefined`, treat it as `FILE_STATUS.UNKNOWN` aka isActive === true
    // This happens when a new submission is made and a file is yet to be uploaded
    if (currentState === undefined) {
        return true;
    }

    return currentStateMatchesFilters(ACTIVE_STATES, currentState);
};
const checkIfIsError = (stepperContext: StepperContextValue) => {
    const { currentState } = stepperContext;
    return currentStateMatchesFilters(ERROR_STATES, currentState);
};
const checkIfIsComplete = (stepperContext: StepperContextValue) => {
    const { stateHistory } = stepperContext;
    return stateHistoryIncludesFilters(COMPLETE_STATES, stateHistory);
};
export default {
    checkIfIsActive,
    checkIfIsError,
    checkIfIsComplete,
};
