import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Paper,
    Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from 'components';
import AccessDenied from 'components/AccessDenied/AccessDenied';
import { DateTime } from 'luxon';
import { getActivityLogEntries } from 'services/api/collections/collections';
import { RestrictAccess } from 'src/components/index';
import { AL_SUBMIT_FOR_APPROVAL } from 'src/constants/activityLogTypeIds';
import { FILE_STATUS, fileStatusById } from 'src/constants/FileStatus';
import { getApprovalStates } from 'src/pages/Collections/Submission/utils';
import useUpdateSubmission from 'src/queries/submissions/useUpdateSubmission';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import { useStepper } from '../Stepper/StepperContext';

import styles from './submit.module.scss';

const SubmitStates = ({
    isLoading,
    hasAlreadySubmitted,
    isSubmitAllowed,
    isSubmissionProcessing,
    submission,
    commitNotAllowedReasons,
    canSubmit,
    termsChecked,
    setTermsChecked,
    hasPermissionToSubmit,
    approvalStates,
    hasOpenIssues,
    collection,
}) => {
    const { data: activityLog, isLoading: activityLogLoading } = useQuery({
        queryKey: ['activity-log-approval', submission?.uuid],
        queryFn: () =>
            getActivityLogEntries({
                submissionUuid: submission?.uuid,
                activityLogTypeId: AL_SUBMIT_FOR_APPROVAL,
            }),
        enabled: Boolean(submission?.uuid),
        refetchInterval: false,
    });

    const approvalTimestamp = useMemo(() => {
        const timestamp =
            submission?.stateHistory?.findLast(
                entry => entry.StatusId === approvalStates?.at(0)?.id,
            )?.Actioned || submission.uploaded;
        return DateTime.fromISO(timestamp).toLocaleString(
            DateTime.DATETIME_MED,
        );
    }, [approvalStates, submission?.stateHistory, submission.uploaded]);

    if (!hasPermissionToSubmit)
        return (
            <Typography>
                You do not have a valid role to submit for approval
            </Typography>
        );

    if (!submission?.fileName)
        return (
            <Typography>
                You have yet to upload a file. Please upload one on the upload
                step.
            </Typography>
        );

    if (!submission.isLatest)
        return (
            <Typography>
                You can only submit the most recently uploaded submission.
            </Typography>
        );

    if (isSubmissionProcessing)
        return (
            <div>
                <Typography>
                    Your submission is currently being processed.
                </Typography>
                <Typography>
                    When your submission reaches the &quot;Quality processing
                    complete&quot; stage you will be able to submit for
                    approval.
                </Typography>
            </div>
        );

    if (hasAlreadySubmitted)
        return (
            <div>
                <div>
                    <span>
                        Your submission <strong>{submission.fileName}</strong>,
                        was submitted for approval on{' '}
                        <strong>{approvalTimestamp}</strong> by{' '}
                        <strong>{activityLog?.records?.at(0)?.username}</strong>
                        . When it has been approved by all required parties, you
                        will be able to start the process to sign off your
                        submission.
                    </span>
                </div>
            </div>
        );

    if (isLoading || activityLogLoading)
        return (
            <div>
                <div className={styles.loading}>
                    <CircularProgress aria-label="Circular loading animation" />
                    <p>Checking the status of the submission</p>
                </div>
            </div>
        );

    if (!isSubmitAllowed && commitNotAllowedReasons)
        return (
            <div>
                {hasOpenIssues && (
                    <Typography className="margin--bottom-1">
                        Please note that you have outstanding issues in IMS or
                        rule failures in the HDP. <br /> Your submission may not
                        be approved unless these relate to Known Issues or you
                        have previously discussed your outstanding issues with
                        the Liaison team.
                    </Typography>
                )}
                <Typography>
                    You cannot yet submit for approval as one or more of the
                    following apply:
                </Typography>
                <ul className={styles.list}>
                    {commitNotAllowedReasons.map((reason, index) => (
                        <li key={index}>
                            {reason
                                .split(/(&e-mail:\S+)/g)
                                .map((parsedItem, parsedIndex) =>
                                    parsedItem.startsWith('&e-mail:') ? (
                                        <a
                                            href={`mailto:${parsedItem.replace(
                                                '&e-mail:',
                                                '',
                                            )}`}
                                            className={styles.emailLink}
                                            key={`commitReason${index}${parsedIndex}`}
                                        >
                                            {parsedItem.replace('&e-mail:', '')}
                                        </a>
                                    ) : (
                                        <span key={parsedItem}>
                                            {parsedItem}
                                        </span>
                                    ),
                                )}
                        </li>
                    ))}
                </ul>
            </div>
        );

    // TODO: Remove this from prod as soon as liaison team is ready to accept submissions
    if (collection?.reference === '23056')
        return (
            <div>
                <Typography className="margin--bottom-0-5">
                    Providers are not currently able to submit for approval,
                    this will be enabled later in the collection and we will
                    communicate to providers once this is done. From 5 September
                    2024, if you are ready for your submission to be checked by
                    HESA quality analysts, please select the &apos;Raise
                    credibility issues&apos; button in the credibility report
                    section of HDP. For more information please see the
                    &apos;Submission stages&apos; section of the support guides:{' '}
                    <a
                        href="https://www.hesa.ac.uk/collection/23056-support-guides"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://www.hesa.ac.uk/collection/23056-support-guides
                    </a>
                </Typography>
            </div>
        );

    if (canSubmit)
        return (
            <div>
                {hasOpenIssues && (
                    <Typography className="margin--bottom-0-5">
                        Please note that you have outstanding issues in IMS or
                        rule failures in the HDP. <br /> Your submission may not
                        be approved unless these relate to Known Issues or you
                        have previously discussed your outstanding issues with
                        the Liaison team.
                    </Typography>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            className="hdp-override"
                            checked={termsChecked}
                            data-test-id={
                                !termsChecked
                                    ? formatTestId('agreeToTerms')
                                    : null
                            }
                            onChange={() => setTermsChecked(!termsChecked)}
                        />
                    }
                    label={`I understand that by submitting file with ID ${submission?.uuid?.slice(
                        -8,
                    )}  for approval, the approval process will commence and I will no longer be able to submit data to this collection unless otherwise instructed.`}
                />
            </div>
        );
};

const Submit = () => {
    const {
        data: submission,
        setPageTitle,
        getSubmission,
        collection,
    } = useOutletContext();
    const { roles, isProvider } = useUserContext();
    const { commitStatusQuery } = useStepper();
    const isHesaAdmin = roles.includes('admin');
    const isProviderSignOff = isProvider && roles.includes('sign-off');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { updateState } = useUpdateSubmission();
    const { submissionStatuses, commitNotAllowedReasons } = useMemo(() => {
        const { data } = commitStatusQuery;
        return data ?? {};
    }, [commitStatusQuery]);

    const isSubmitAllowed = useMemo(() => {
        if (commitStatusQuery.isLoading) return false;
        return (
            (!submissionStatuses.isNotLatest &&
                !submissionStatuses.notCollectionEndDate &&
                !submissionStatuses.operationalFailures &&
                !submissionStatuses.pgrTransferConsentGiven) ||
            !commitNotAllowedReasons?.length
        );
    }, [
        commitNotAllowedReasons?.length,
        commitStatusQuery.isLoading,
        submissionStatuses,
    ]);
    const hasOpenIssues = useMemo(() => {
        if (commitStatusQuery.isLoading) return false;
        return submissionStatuses.openIssues || submissionStatuses.ruleFailures;
    }, [commitStatusQuery.isLoading, submissionStatuses]);

    const submissionApprovalStates = useMemo(() => {
        return getApprovalStates(
            submission?.provider?.riskCode,
            submission?.provider?.countryCode,
        );
    }, [submission?.provider]);

    const [termsChecked, setTermsChecked] = useState(false);
    const { navigateToStep } = useStepper();

    useEffect(() => {
        setPageTitle('Submit');
    }, [setPageTitle]);

    const hasAlreadySubmitted = useMemo(
        () =>
            fileStatusById(submission?.status?.id)?.order >
            FILE_STATUS.QUALITY_PROCESSING_COMPLETE.order,
        [submission?.status?.id],
    );

    const isSubmissionProcessing = useMemo(
        () =>
            fileStatusById(submission?.status?.id)?.order <
            FILE_STATUS.QUALITY_PROCESSING_COMPLETE.order,
        [submission?.status?.id],
    );

    const hasPermissionToSubmit = useMemo(
        () => isHesaAdmin || isProviderSignOff,
        [isHesaAdmin, isProviderSignOff],
    );

    const canSubmit = useMemo(() => {
        if (collection?.reference === '23056') return false; // Prevent submit for approval for collection 23056 - this is temporary and only in production for HDP-3870
        if (hasAlreadySubmitted) return true;

        return hasPermissionToSubmit && isSubmitAllowed;
    }, [
        collection?.reference,
        hasAlreadySubmitted,
        hasPermissionToSubmit,
        isSubmitAllowed,
    ]);

    const handleSubmit = useCallback(() => {
        if (hasAlreadySubmitted) {
            return navigateToStep('Approval');
        }

        setIsSubmitting(true);

        updateState(
            {
                submissionId: submission.uuid,
                statusId: submissionApprovalStates?.at(0)?.id,
                action: 'submit',
            },
            {
                onSuccess: () => {
                    setIsSubmitting(false);
                    navigateToStep('Approval');
                },
                onSettled: () => {
                    getSubmission();
                },
            },
        );
    }, [
        hasAlreadySubmitted,
        updateState,
        submission.uuid,
        submissionApprovalStates,
        navigateToStep,
        getSubmission,
    ]);

    const getStateString = (isSubmitting, hasAlreadySubmitted) => {
        if (isSubmitting) return 'Submitting';
        if (hasAlreadySubmitted) return 'Continue';
        return 'Submit for approval';
    };

    return (
        <RestrictAccess
            allowPermissions={['collections.approval-and-sign-off']}
            render={() => <AccessDenied mt={8} />}
        >
            <Paper className={styles.submit}>
                <h2 className={styles.heading}>Submit for approval</h2>
                <SubmitStates
                    isLoading={commitStatusQuery?.isLoading}
                    isSubmissionProcessing={isSubmissionProcessing}
                    isSubmitAllowed={isSubmitAllowed}
                    hasAlreadySubmitted={hasAlreadySubmitted}
                    submission={submission}
                    commitNotAllowedReasons={commitNotAllowedReasons}
                    canSubmit={canSubmit}
                    hasOpenIssues={hasOpenIssues}
                    termsChecked={termsChecked}
                    setTermsChecked={setTermsChecked}
                    hasPermissionToSubmit={hasPermissionToSubmit}
                    approvalStates={submissionApprovalStates}
                    collection={collection}
                />
            </Paper>
            <div className={styles.buttons}>
                <Button
                    className="hdp-override large"
                    disabled={
                        !hasAlreadySubmitted &&
                        (!canSubmit || !termsChecked || isSubmitting)
                    }
                    disableRipple={true}
                    variant="contained"
                    onClick={() => handleSubmit()}
                    data-test-id={
                        hasAlreadySubmitted
                            ? formatTestId('continue')
                            : formatTestId('submit')
                    }
                >
                    {getStateString(isSubmitting, hasAlreadySubmitted)}
                </Button>
                <Button
                    className="hdp-override large hdp-override--grey"
                    data-test="Back"
                    disabled={false}
                    disableRipple={true}
                    variant="contained"
                    onClick={() => navigateToStep('Quality Assurance')}
                    data-test-id="back"
                >
                    Back
                </Button>
            </div>
        </RestrictAccess>
    );
};

export default Submit;
