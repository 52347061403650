import jsonCamelCaseResolver from '../jsonCamelCaseResolver/jsonCamelCaseResolver';

type SchemaErrorId = {
    field: string;
    value: string;
};

type SchemaErrorDetails = {
    name: string;
    ids: SchemaErrorId[];
    parent: SchemaErrorDetails | null;
};

export const formatSchemaErrorIds = (details: string | null) => {
    if (details === null) {
        return '';
    }

    const schemaErrorDetails: SchemaErrorDetails = JSON.parse(
        details,
        jsonCamelCaseResolver,
    );

    const arr: string[] = [];

    formatSchemaId(schemaErrorDetails, arr);

    const errorString = arr.reduce((acc, curr) => {
        return `${acc} from parent ${curr}`;
    });

    return errorString;
};

const formatSchemaId = (
    schemaErrorDetails: SchemaErrorDetails,
    arr: string[],
) => {
    const { name, ids, parent } = schemaErrorDetails;

    if (ids.length > 0) {
        const idString = ids
            .map(id => `${name}.${id.field}: '${id.value}'`)
            .reduce((acc, curr) => {
                return `${acc}, ${curr}`;
            });

        arr.push(idString);
    } else {
        arr.push(`${name}.<NO ID>`);
    }

    if (parent !== null) {
        formatSchemaId(parent, arr);
    }
};
