import { createContext, ReactNode, useContext, useState } from 'react';

interface MessageAnnouncerContext {
    addMessageToQueue: (message: string) => void;
    clearMessages: () => void;
    messages: string[];
}

export const MessageAnnouncerContext = createContext<MessageAnnouncerContext>({
    addMessageToQueue: () => {},
    clearMessages: () => {},
    messages: [],
});

/**
 * @description Provides a context for announcing messages to the user.
 *  Messages are queued using the `addMessageToQueue` function and are displayed in a
 *  hidden, aria-live region on the page for screen readers to announce.
 *  The messages are cleared using the `clearMessages` function. This is useful for
 *  removing messages that are no longer relevant to the user after a component unmounts.
 */
export const MessageAnnouncerProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [messages, setMessages] = useState<string[]>([]);

    const addMessageToQueue = (message: string) => {
        setTimeout(
            () =>
                setMessages(messages =>
                    Array.from(new Set([...messages, message])),
                ),
            500,
        ); // Add a delay to allow the message to be announced
    };

    return (
        <MessageAnnouncerContext.Provider
            value={{
                addMessageToQueue,
                clearMessages: () => setMessages([]),
                messages,
            }}
        >
            {children}
        </MessageAnnouncerContext.Provider>
    );
};

export const useMessageAnnouncer = () => {
    const context = useContext(MessageAnnouncerContext);
    if (!context) {
        throw new Error(
            'useMessageAnnouncer must be used within a MessageAnnouncerProvider',
        );
    }
    return context;
};
