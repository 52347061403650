import { FILE_STATUS } from 'constants/FileStatus';
import { Collection } from 'src/types/collection';

import CollectionIcon from './CollectionIcon';
import { formatDate } from './utils';

import styles from './submissions.module.scss';

const SubmissionAccordionHeader = ({
    collection,
    isOvt = false,
}: {
    collection: Collection;
    isOvt?: boolean;
}) => {
    const from = formatDate(collection.referencePeriodStart);
    const to = formatDate(collection.referencePeriodEnd);

    return (
        <>
            <span className={styles.accordionName}>{collection.name}</span>
            <span className={styles.accordionDate}>{`${from} - ${to}`}</span>
            {!isOvt && (
                <>
                    <span className={styles.accordionSubmissions}>
                        {collection.collectionSubmissionCount}
                    </span>
                    <span className={styles.accordionStatus}>
                        <span className={styles.icon}>
                            <CollectionIcon collection={collection} />
                        </span>
                        <span>
                            {FILE_STATUS[collection.collectionStatus]
                                ? FILE_STATUS[collection.collectionStatus].label
                                : ''}
                        </span>
                    </span>
                </>
            )}
        </>
    );
};

export default SubmissionAccordionHeader;
