import { useCallback, useEffect, useRef, useState } from 'react';
import {
    generatePath,
    Outlet,
    useMatches,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useUserContext } from 'components';
import BannerBox from 'components/BannerBox/BannerBox';
import { useMessageAnnouncer } from 'components/MessageAnnouncer/MessageAnnouncerContext';
import { PageTitle, PageTitleProvider } from 'src/components/index';
import { PATHS } from 'src/constants/constants';
import useSubmission from 'src/queries/submissions/useSubmission';
import useSubmissions from 'src/queries/submissions/useSubmissions';
import { connectWebSocket } from 'src/services/api/utils';
import { Collection } from 'src/types/collection';
import { RawSubmission } from 'src/types/submission';
import { User } from 'src/types/user';
import { HdpMessage } from 'src/types/websocket';

import { useCollections } from '../hooks';

import Stepper from './Steps/Stepper';
import { StepName } from './Steps/Stepper/types';
import { collectionAllowsSubmissions, inferStepLabelFromRoute } from './utils';

const SUBMISSION_STEPS: StepName[] = [
    'Upload',
    'Processing',
    'Quality Assurance',
    'Submit',
    'Approval',
    'Sign-off',
];

const OVT_SUBMISSION_STEPS: StepName[] = [
    'Upload',
    'Processing',
    'Quality Assurance',
];

interface SubmissionProps {
    isOvt?: boolean;
}

export const Submission = ({ isOvt = false }: SubmissionProps) => {
    const params = useParams<
        'submissionId' | 'collectionId' | 'step' | 'provider' | 'reference'
    >();
    const matches = useMatches();
    const steps = isOvt ? OVT_SUBMISSION_STEPS : SUBMISSION_STEPS;

    const {
        submissionId = null,
        collectionId = null,
        step = null,
        reference = null,
    } = params;

    const {
        data: submission,
        isLoading,
        refetch: refetchSubmission,
    } = useSubmission({
        submissionId: submissionId!,
        enabled: Boolean(submissionId),
        isOvt,
    });

    const socketRef = useRef<WebSocket | null>();
    const { activeOrganisation, isHesa, isStatutoryCustomer, roles } =
        useUserContext() as User;
    const { clearMessages } = useMessageAnnouncer();

    // State
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState(
        inferStepLabelFromRoute(matches, steps) as string,
    );
    const [latestMessage, setLatestMessage] = useState<HdpMessage | null>();
    const [tsvDeliveryLocation, setTsvDeliveryLocation] = useState(
        submission?.tsvDeliveryLocation,
    );
    const [qualityProcessingComplete, setQualityProcessingComplete] =
        useState(false);
    const [processingComplete, setProcessingComplete] = useState(false);

    const instId = isHesa
        ? submission?.provider.instId
        : (activeOrganisation.id! as string);
    const isAdmin = roles.includes('admin');
    const isStatutoryCustomerAnalyst =
        isStatutoryCustomer && roles.includes('analyst');

    const { refetch: getSubmissions } = useSubmissions({
        collectionId: collectionId!,
        instId: instId!,
        enabled:
            Boolean(instId) &&
            Boolean(collectionId) &&
            Boolean(!isStatutoryCustomerAnalyst) &&
            Boolean(!isOvt),
    });

    useEffect(() => {
        return () => {
            clearMessages(); // Clear messages when the submission page is unmounted
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedirect = useCallback(
        (reprocessedSubmission: RawSubmission) => {
            if (reprocessedSubmission?.Uuid) {
                const basePath =
                    isHesa || isAdmin || isStatutoryCustomerAnalyst
                        ? PATHS.ADMIN_SUBMISSION
                        : PATHS.SUBMISSION;
                const pathToReprocessedSubmission = generatePath(basePath, {
                    collectionId,
                    instId: instId!,
                    reference,
                    submissionId: reprocessedSubmission?.Uuid,
                });

                navigate(pathToReprocessedSubmission);
                location.reload();
            }
        },
        [
            collectionId,
            instId,
            isAdmin,
            isHesa,
            isStatutoryCustomerAnalyst,
            navigate,
            reference,
        ],
    );

    const handleMessage = useCallback(
        async (msg: MessageEvent) => {
            const inboundMessage: HdpMessage = JSON.parse(msg.data);

            const shouldProcessMessage =
                inboundMessage.instId === instId &&
                inboundMessage.submissionId &&
                submission?.uuid;

            if (!shouldProcessMessage) return;

            if (
                inboundMessage.submissionId !== submission.uuid &&
                !isStatutoryCustomerAnalyst
            ) {
                const { data: providerSubmissions } = await getSubmissions();

                const currentSubmission = providerSubmissions?.findLast(
                    providerSubmission =>
                        providerSubmission.Uuid === submission.uuid,
                );
                const reprocessedSubmission = providerSubmissions?.findLast(
                    providerSubmission => {
                        return (
                            providerSubmission.Uuid ===
                                inboundMessage.submissionId &&
                            providerSubmission.CopiedFrom ===
                                currentSubmission?.SubmissionId.toString()
                        );
                    },
                );

                if (reprocessedSubmission) {
                    handleRedirect(reprocessedSubmission);
                }
            } else {
                switch (inboundMessage?.action) {
                    case 'file-event':
                        setLatestMessage(inboundMessage);
                        break;
                    case 'tsv-ready':
                        setTsvDeliveryLocation(
                            inboundMessage?.tsvDeliveryLocation,
                        );
                        break;
                    default:
                        break;
                }
            }
        },
        [
            getSubmissions,
            handleRedirect,
            instId,
            isStatutoryCustomerAnalyst,
            submission?.uuid,
        ],
    );

    useEffect(() => {
        const socketExists = !!socketRef.current;

        const createConnection = () => {
            socketRef.current = connectWebSocket(instId);
            socketRef.current.onmessage = handleMessage;
        };

        if (!socketExists && instId && submission?.uuid) {
            createConnection();
        }

        if (
            socketExists &&
            socketRef.current?.readyState === WebSocket.CLOSED
        ) {
            createConnection();
        }
    }, [instId, handleMessage, submission?.uuid]);

    useEffect(() => {
        return () => {
            if (socketRef.current?.readyState === WebSocket.OPEN) {
                socketRef.current?.close();
                socketRef.current = null;
            }
        };
    }, []);

    const { data: collections } = useCollections({
        instId,
        state: isOvt
            ? ['open', 'closed']
            : ['open', 'closed', 'historic_amendment'],
    });

    const collection: Collection = collections?.find(
        (c: Collection) => c.id === parseInt(collectionId!, 10),
    );

    useEffect(() => {
        if (submission === undefined && latestMessage?.status === 'UPLOADED') {
            refetchSubmission();
        } else if (
            submission !== undefined &&
            submission.status &&
            latestMessage
        ) {
            // Keep updating the submission while the submission is uploading.
            // Stop updating after we hit 'QUALITY_PROCESSING_COMPLETE'.

            if (
                submission?.status?.code !== latestMessage?.status &&
                !qualityProcessingComplete
            ) {
                refetchSubmission();

                if (latestMessage.status === 'QUALITY_PROCESSING_COMPLETE') {
                    setQualityProcessingComplete(true);
                }
            }
        }
    }, [
        latestMessage,
        submissionId,
        step,
        qualityProcessingComplete,
        submission,
        refetchSubmission,
    ]);

    return (
        <section>
            {isOvt && (
                <BannerBox
                    heading="Online validation tool"
                    upperText="This is the online validation tool (OVT). This tool is designed for providers to validate their files. Once your file is ready to be analysed you will need to upload it to the relevant live collection. Please note that only the latest uploaded file will be available to view."
                    lowerText={
                        <span>
                            If you need support please contact{' '}
                            <a href="mailto:liaison@hesa.ac.uk">
                                HESA Liaison.
                            </a>
                        </span>
                    }
                />
            )}
            {!isOvt && collection?.disallowCreateIssues && (
                <BannerBox
                    heading="The system is now creating issues automatically"
                    upperText="All outstanding issues have been created in the Issue Management System (IMS), these are no longer required to be raised manually.
                    "
                />
            )}
            {isLoading ? (
                <CircularProgress aria-label="Circular loading animation" />
            ) : (
                <PageTitleProvider>
                    <PageTitle title={pageTitle} />
                    <Stepper
                        submissionData={submission}
                        submissionId={submissionId!}
                        collectionId={collectionId!}
                        steps={steps}
                        key={`stepper-${submissionId}`}
                        ovt={isOvt}
                    >
                        <Outlet
                            context={{
                                collectionId,
                                submissionId: submission?.uuid,
                                data: submission,
                                instId,
                                latestMessage,
                                collection,
                                processingComplete,
                                setProcessingComplete,
                                tsvDeliveryLocation,
                                setPageTitle,
                                getSubmission: refetchSubmission,
                                isAnalyst: isStatutoryCustomerAnalyst,
                                canUploadNewSubmission: Boolean(
                                    collection &&
                                        collectionAllowsSubmissions(collection),
                                ),
                                isOvt,
                            }}
                            key={`outlet-${submissionId}`}
                        />
                    </Stepper>
                </PageTitleProvider>
            )}
        </section>
    );
};

Submission.displayName = 'Submission';
