import { useQuery } from '@tanstack/react-query';

import { findCollectionByReference } from './helpers';
import { getUseSingleCollectionQueryOptions } from './options';

// Return a specific collection by reference
function useSingleCollection({ reference }: { reference: string | undefined }) {
    const options = getUseSingleCollectionQueryOptions();
    return useQuery({
        ...options,
        select: data => findCollectionByReference(data.collections, reference),
    });
}

export default useSingleCollection;
