import { useEffect, useMemo, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
} from '@mui/material';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon.svg';
import { useMessageAnnouncer } from 'components/MessageAnnouncer/MessageAnnouncerContext';
import { DateTime } from 'luxon';
import { RestrictAccess } from 'src/components';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import ContinueBackUploadNew from 'src/components/ContinueBackUploadNew/ContinueBackUploadNew';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionSummary,
} from 'src/components/HDPAccordion';
import SchemaFailuresReport from 'src/components/SchemaFailuresReport/SchemaFailuresReport';
import { ACCORDION_IDS } from 'src/constants/constants';
import {
    fileStatusById,
    fileStatusByKey,
    ifStatusIdIsUpToQualityProcessingComplete,
    STATUS_ICONS,
} from 'src/constants/FileStatus';
import { processingConfig } from 'src/pages/Collections/OnlineValidation/Steps/Processing/processing.config';
import { useStepper } from 'src/pages/Collections/Submission/Steps/Stepper/StepperContext';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import {
    requestOvtFileDownload,
    requestOvtSchemaErrorsFileDownload,
} from '../../utils';

import styles from './processing.module.scss';

const displayStatesConstants = {
    ERROR: 'error',
    WAITING: 'waiting',
    PASSED: 'passed',
};

const displayStateIcons = {
    [displayStatesConstants.WAITING]: STATUS_ICONS.STATE_IN_PROGRESS,
    [displayStatesConstants.ERROR]: STATUS_ICONS.STATE_FAILED,
    [displayStatesConstants.PASSED]: STATUS_ICONS.STATE_CAN_PROGRESS,
};

const Processing = () => {
    const {
        data,
        instId,
        collectionId,
        submissionId,
        setPageTitle,
        collection,
    } = useOutletContext();
    const { addMessageToQueue } = useMessageAnnouncer();
    const { stateHistory } = useStepper();

    const furthestPassedErroredStep = useRef(null);

    useEffect(() => {
        setPageTitle('Processing');
    }, [setPageTitle]);

    const checkIfResubmissionAllowed = () =>
        data?.status.code === 'RESUBMISSION_REQUIRED' ||
        ifStatusIdIsUpToQualityProcessingComplete(data?.status.id);

    const checkIfErrorInStates = (states, submissionStateId) =>
        states.some(state => fileStatusByKey(state).id === submissionStateId);

    const checkIfWaitingInStates = (states, submissionStateId) =>
        states.some(
            state =>
                fileStatusByKey(state).order >
                    fileStatusById(submissionStateId).order ||
                fileStatusByKey(state).order ===
                    fileStatusById(submissionStateId).order,
        );

    const checkIfPassedInStates = (states, submissionStateId) =>
        states.some(
            state =>
                fileStatusByKey(state).order <=
                fileStatusById(submissionStateId).order,
        );

    // FIXME: Quick work-around to fix HDP-3777 - this should be refactored in HDP-3729
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getStateForStep = (displayStates, submissionStateId) => {
        if (
            checkIfErrorInStates(
                displayStates[displayStatesConstants.ERROR].states,
                submissionStateId,
            )
        ) {
            return {
                displayState: displayStatesConstants.ERROR,
                label: displayStates[displayStatesConstants.ERROR].label,
            };
        }

        if (
            checkIfPassedInStates(
                displayStates[displayStatesConstants.PASSED].states,
                submissionStateId,
            )
        ) {
            return {
                displayState: displayStatesConstants.PASSED,
                label: displayStates[displayStatesConstants.PASSED].label,
            };
        }

        if (
            checkIfWaitingInStates(
                displayStates[displayStatesConstants.WAITING].states,
                submissionStateId,
            )
        ) {
            return {
                displayState: displayStatesConstants.WAITING,
                label: displayStates[displayStatesConstants.WAITING].label,
            };
        }
    };

    const enrichedFileReady = useMemo(
        () =>
            data?.stateHistory?.some(
                state => state.Code === 'ENRICHMENT_PASSED',
            ),
        [data?.stateHistory],
    );

    const schemaErrorsFileReady = useMemo(
        () =>
            data?.stateHistory?.some(
                state => state.Code === 'SCHEMA_CHECK_FAILED',
            ),
        [data?.stateHistory],
    );

    const isContinuePossible = useMemo(
        () =>
            stateHistory?.some(
                state => state.Code === 'QUALITY_PROCESSING_COMPLETE',
            ),
        [stateHistory],
    );

    // FIXME: Quick work-around to fix HDP-3777 - this should be refactored in HDP-3729
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const DownloadButton = ({
        step,
        enrichedFileReady,
        schemaErrorsFileReady,
    }) => {
        if (step.name === 'Enrichment' && enrichedFileReady) {
            return (
                <DownloadIcon
                    tabIndex={0}
                    role="button"
                    data-test-id={formatTestId('download', 'enriched file')}
                    className={styles.fileDownload}
                    aria-label="Download enriched file"
                    onFocus={e => e.stopPropagation()}
                    onClick={() =>
                        requestOvtFileDownload(
                            data.uuid,
                            instId,
                            data.fileName,
                            data.uploaded,
                            true,
                        )
                    }
                />
            );
        } else if (step.name === 'Schema check' && schemaErrorsFileReady) {
            return (
                <DownloadIcon
                    tabIndex={0}
                    role="button"
                    data-test-id={formatTestId(
                        'download',
                        'schema errors file',
                    )}
                    className={styles.fileDownload}
                    aria-label="Download schema errors file"
                    onFocus={e => e.stopPropagation()}
                    onClick={() =>
                        requestOvtSchemaErrorsFileDownload(
                            data.uuid,
                            instId,
                            data.fileName,
                            data.uploaded,
                        )
                    }
                />
            );
        }
        return null;
    };

    const renderProcessingSteps = status => {
        return processingConfig.map(step => {
            const { displayState, label } = getStateForStep(
                step.displayStates,
                status.id,
            );

            if (
                displayState === displayStatesConstants.PASSED ||
                displayState === displayStatesConstants.ERROR
            ) {
                furthestPassedErroredStep.current = {
                    ...step,
                    state: displayState,
                };
            }
            return (
                <ListItem key={crypto.randomUUID()} className={styles.state}>
                    <ListItemIcon className={styles.icon}>
                        {displayStateIcons[displayState]}
                    </ListItemIcon>
                    <ListItemText primary={label} className={styles.label} />
                    <DownloadButton
                        step={step}
                        enrichedFileReady={enrichedFileReady}
                        schemaErrorsFileReady={schemaErrorsFileReady}
                    />
                </ListItem>
            );
        });
    };

    useEffect(() => {
        if (furthestPassedErroredStep.current) {
            const inErrorState =
                furthestPassedErroredStep.current.state === 'error';
            let message;
            if (
                furthestPassedErroredStep.current.name ===
                'Quality processing complete'
            ) {
                message = 'Quality processing complete';
            } else {
                message = inErrorState
                    ? `Processing step ${furthestPassedErroredStep.current.name}, has errors`
                    : `Processing step ${furthestPassedErroredStep.current.name}, has passed`;
            }
            addMessageToQueue(message);
        }
    }, [furthestPassedErroredStep, data, addMessageToQueue]);

    return (
        <RestrictAccess
            allowPermissions={['online-validation.processing-ovt']}
            render={() => <AccessDenied mt={8} />}
        >
            {data.status ? (
                <div>
                    <Paper className={styles.wrapper}>
                        <h2 className={styles.heading}>{data.fileName}</h2>
                        <h3 className={styles.subHeading}>
                            Uploaded:{' '}
                            {DateTime.fromISO(data.uploaded).toLocaleString(
                                DateTime.DATE_FULL,
                            )}
                        </h3>
                        <List className={styles.states}>
                            {renderProcessingSteps(data.status)}
                        </List>
                    </Paper>
                    {data?.status.id === 8 || data?.status.id === 9 ? (
                        <HDPAccordion
                            searchParamId={
                                ACCORDION_IDS.SUBMISSION
                                    .SCHEMA_VALIDATION_ERRORS
                            }
                        >
                            <HDPAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                header="Schema validation errors"
                                data-test-id={formatTestId(
                                    'open accordion',
                                    'schema validation errors',
                                )}
                            />
                            <HDPAccordionDetails>
                                <SchemaFailuresReport
                                    instId={instId}
                                    submissionId={submissionId}
                                    isOvt={true}
                                />
                            </HDPAccordionDetails>
                        </HDPAccordion>
                    ) : (
                        ''
                    )}
                    <ContinueBackUploadNew
                        isContinuePossible={isContinuePossible}
                        collectionId={collectionId}
                        collectionReference={collection?.reference}
                        instId={instId}
                        isOvt={true}
                        nextStepName="Quality processing"
                        previousStepName="Upload"
                        uploadPermissionsList={[
                            'online-validation.online-validation',
                        ]}
                        isResubmissionAllowed={checkIfResubmissionAllowed()}
                    />
                </div>
            ) : (
                ''
            )}
        </RestrictAccess>
    );
};

export default Processing;
