import { useQuery } from '@tanstack/react-query';
import { providers as providersApi } from 'src/services/api';

export const useCollections = ({ instId, state }) =>
    useQuery({
        queryKey: ['collections', instId, state],
        queryFn: () =>
            providersApi.getCollections({
                instId,
                state,
            }),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });
