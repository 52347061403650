import { useQuery } from '@tanstack/react-query';
import { GetSubmissionsArgs } from 'services/api/submissions/types';

import { getUseSubmissionsOptions } from './options';

interface UseSubmissionsArgs extends GetSubmissionsArgs {
    enabled?: boolean;
}

export default function useSubmissions({
    enabled = true,
    ...args
}: UseSubmissionsArgs) {
    const options = getUseSubmissionsOptions(args);
    return useQuery({
        ...options,
        enabled,
        select: data => {
            const sortedSubmissions = data.toSorted(
                (a, b) => b.SubmissionId - a.SubmissionId,
            );

            return sortedSubmissions;
        },
    });
}
