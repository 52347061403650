import { Collection } from 'services/api/collections/types';

export const findCollectionByReference = (
    collections: Collection[],
    reference: string | undefined,
) => {
    if (!reference) {
        return undefined;
    }
    return collections.find(collection => collection.reference === reference);
};
