import { FILE_STATUS } from 'src/constants/FileStatus';

import { StepperContextValue } from '../types';

import { currentStateMatchesFilters } from './utils';

const ACTIVE_STATES = [FILE_STATUS.APPROVED, FILE_STATUS.PROVIDER_SIGNOFF];
const COMPLETE_STATES = [FILE_STATUS.SIGNED_OFF];

const checkIfIsActive = (stepperContext: StepperContextValue) => {
    const { currentState } = stepperContext;

    const isActive = currentStateMatchesFilters(ACTIVE_STATES, currentState);

    return isActive;
};
const checkIfIsComplete = (stepperContext: StepperContextValue) => {
    const { currentState } = stepperContext;
    const isComplete = currentStateMatchesFilters(
        COMPLETE_STATES,
        currentState,
    );
    return isComplete;
};

const checkIfIsError = () => {
    return false;
};

export default {
    checkIfIsActive,
    checkIfIsComplete,
    checkIfIsError,
};
