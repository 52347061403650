import { Remove } from '@mui/icons-material';
import { FILE_STATUS } from 'constants/FileStatus';
import { Collection } from 'src/types/collection';

import { collectionContainsNilReturnSubmission } from './utils';

export default function CollectionIcon({
    collection,
}: {
    collection: Collection;
}) {
    if (FILE_STATUS[collection.collectionStatus]) {
        return FILE_STATUS[collection.collectionStatus].statusIcon;
    } else if (collectionContainsNilReturnSubmission(collection)) {
        return <span>Nil return</span>;
    } else {
        return <Remove />;
    }
}
